import React from "react";

const Documentation = () => {
  return (
    <div className="lg:mt-[130px] md:mt-[100px] sm:mt-[70px] mt-[65px] max-w-[1350px] px-4 mx-auto" name="stack_section">
      <h1 className="text-black lg:text-[56px] md:text-[44px] sm:text-[36px] text-[30px] font-bold lg:leading-[76px] md:leading-[60px] sm:leading-[48px] leading-[36px] font-sathosi text-center ">
        Our Documentation Stack
      </h1>
      <div className="mt-6 ">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 ">
          {/* first card */}
          <div className="relative w-full ">
            <div className="bg-[#00B8D9]  bg-opacity-10  2xl:h-[370px] w-[80%]  sm:h-[350px] h-[300px] rounded-xl ">
              <div className="bg-white rounded-xl absolute left-[10%] top-[50%] translate-y-[-50%]   px-8 w-[85%] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl ">
                <h4 className="relative mb-[50px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px]   text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                  Writing
                </h4>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 46.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Google Docs</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/download (3) 1 (1).png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">GitBook</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 19.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Notion</p>
                </div>
              </div>
            </div>

          </div>


          {/* second card */}
          <div className="relative">
            <div className="bg-[#EBF7F2]  2xl:h-[370px]  w-[80%]  sm:h-[350px] h-[300px] rounded-xl">
              <div className="bg-white rounded-xl absolute left-[10%] top-[50%] translate-y-[-50%] px-8 w-[85%] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl ">
                <h4 className="relative mb-[50px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px] text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                  Screenshots
                </h4>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/Frame (1).png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Annotely</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 20.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Snagit</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 4.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Greenshot</p>
                </div>
              </div>
            </div>
          </div>


          {/* third card */}
          <div className="relative">
            <div className="bg-[#FEF7E7]  2xl:h-[370px]  w-[80%]  sm:h-[350px] h-[300px] rounded-xl">
              <div className="bg-white rounded-xl absolute left-[10%] top-[50%] translate-y-[-50%] px-8 w-[85%] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl">
                <h4 className="relative mb-[50px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px] text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                  Demos
                </h4>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 47.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Arcade Software</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 5.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Loom</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 16.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Guidejar</p>
                </div>
              </div>
            </div>
          </div>

          {/* fourth card */}
          <div className="relative">
            <div className="bg-[#FEF7E7]  2xl:h-[370px]  w-[80%]  sm:h-[350px] h-[300px] rounded-xl">
              <div className="bg-white rounded-xl absolute left-[10%] top-[50%] translate-y-[-50%] px-8 w-[85%] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl">
                <h4 className="relative mb-[50px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px] text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                  Diagrams
                </h4>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/lucidchart 1.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Lucidchart</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/Vector.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Mermaid</p>
                </div>
                <div className="flex items-center mb-6 sm:mb-8">
                  <img src="images/image 49.png" alt="" className="inline-block w-6 mr-4 " />
                  <p className="inline-block text-black font-poppins font-medium sm:text-[18px] text-[16px] sm:leading-[25px] leading-[22px]">Draw.io</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Documentation;
